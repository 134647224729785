<div class="card__wrapper">
  <mat-grid-list cols="3" rowHeight="10px" gutterSize="35px">
    <!--Welcome-->
    <mat-grid-tile [colspan]="2" [rowspan]="2">
      <div class="welcome-container">
        <div class="welcome-title">
          {{ 'wc.admin.dashboard.welcome.title' | translate }}, {{ userProfile?.username }}
          <button *ngIf="eshopUrl" (click)="goShopping()" mat-raised-button color="primary" class="btn -primary icon stroke" id="001_dashboard">
            {{ 'wc.admin.dashboard.welcome.button.visitMyShop' | translate }}
            <mat-icon svgIcon="externalLink" ></mat-icon>
          </button>
        </div>
      </div>
    </mat-grid-tile>
    <!--Total confirmed orders-->
    <mat-grid-tile [colspan]="1" [rowspan]="5">
      <app-total-confirmed-orders class="dashboard_component"></app-total-confirmed-orders>
    </mat-grid-tile>
    <!--Quick actions-->
    <mat-grid-tile [colspan]="2" [rowspan]="6" class="dashboard_tile">
      <app-quick-actions class="dashboard_component"></app-quick-actions>
    </mat-grid-tile>
    <!--Top 3 products-->
    <mat-grid-tile [colspan]="1" [rowspan]="7">
      <app-top-products class="dashboard_component"></app-top-products>
    </mat-grid-tile>
    <!--Tickets-->
    <mat-grid-tile *ngIf="!disableTickets" [colspan]="2" [rowspan]="7">
      <app-ticket-lists class="dashboard_component"></app-ticket-lists>
    </mat-grid-tile>
    <!--Last Changed products-->
    <mat-grid-tile [colspan]="1" [rowspan]="7">
      <app-last-changes class="dashboard_component"></app-last-changes>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="help-button">
    <a id="002_dashboard">{{ 'wc.admin.dashboard.help' | translate }}</a>
    <button mat-fab>
      <mat-icon svgIcon="help"></mat-icon>
    </button>
  </div>
</div>
