<div class="page__wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.notification.type.edit' | translate }}</h1>
    <div class="page__menu">
      <a
        color="primary"
        mat-raised-button
        (click)="save()"
        [routerLink]=""
        class="btn -primary -xs"
        *ngIf="aclService.pageEditAccess"
        >{{ 'wc.common.save' | translate }}</a
      >
      <a
        id="003_cms_contents_edit"
        color="primary"
        mat-stroked-button
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs more-actions icon dropDownIcon currentColor"
        [matMenuTriggerFor]="menu"
        #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
        <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"></mat-icon>
      </a>
      <mat-menu #menu="matMenu" class="module-actions">
        <a mat-menu-item (click)="reset()" [routerLink]="">
          <mat-icon svgIcon="reset"></mat-icon>
          {{ 'wc.common.reset' | translate }}
        </a>
        <a mat-menu-item *ngIf="notificationTypeDto?.id" (click)="duplicate()" [routerLink]="">
          <mat-icon svgIcon="duplicate"></mat-icon>
          {{'wc.common.duplicate' | translate}}
        </a>
        <a mat-menu-item *ngIf="notificationTypeDto?.id" (click)="delete()" [routerLink]="">
          <mat-icon svgIcon="delete"></mat-icon>
          {{'wc.common.delete' | translate}}
        </a>
      </mat-menu>
      <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
    </div>
  </div>

  <div class="card__wrapper" *ngIf="notificationTypeDto && notificationTypeInitialized">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.type.coreAttributes' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="typeForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="typeForm"
                    (change)="checkUniqueCode()"
                    [controlName]="'notificationType'"
                    [inputType]="'text'"
                    [label]="'code'"
                    [translationPrefix]="'wc.admin.notification.type'">
                  </app-custom-input>
                </div>
                <div class="form__row wider">
                  <app-custom-input
                    [parentGroup]="typeForm"
                    [controlName]="'remark'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification.type'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [inputType]="'checkbox'"
                    [parentGroup]="typeForm"
                    [controlName]="'productionEnabled'"
                    [label]="'wc.admin.notification.type.productionEnabled'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [inputType]="'checkbox'"
                    [parentGroup]="typeForm"
                    [controlName]="'sendingEnabled'"
                    [label]="'wc.admin.notification.type.sendingEnabled'">
                  </app-custom-input>
                </div>
                <div class="form__row">&nbsp;</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.type.genericParameters' | translate }}
          <a color="primary" mat-raised-button (click)="addNewParameter()" [routerLink]="" class="btn -primary -xs">
            {{ 'wc.admin.notification.type.param.add' | translate }}</a
          >
        </div>
        <div class="card__body">
          <form *ngIf="typeParametersFormArray.controls.length" [formGroup]="typeParamFormGroup" class="form">
            <div class="form__groups" formArrayName="parameters">
              <div class="form__group -flex" *ngFor="let paramForm of typeParametersFormArray.controls; let i = index">
                <ng-container [formGroupName]="i">
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="paramForm"
                      [controlName]="'name'"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.notification.type.param'">
                    </app-custom-input>
                  </div>
                  <div class="form__row wider">
                    <app-custom-input
                      [parentGroup]="paramForm"
                      [controlName]="'value'"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.notification.type.param'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <a
                      color="primary"
                      mat-raised-button
                      (click)="deleteParameter(i)"
                      [routerLink]=""
                      class="btn -primary -xs">
                      {{ 'wc.admin.notification.type.param.delete' | translate }}</a
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.type.templates' | translate }}
        </div>
        <div class="card__body" [formGroup]="typeGatewayFormGroup">
          <ng-container formArrayName="groupedTypeGateways">
            <div class="group-gateways-tab">
              <div
                class="group-gateways-tab-item"
                *ngFor="let groupedTypeGatewayForm of groupedTypeGatewaysFormArray.controls; let i = index"
                (click)="selectGroupGatewayType(groupedTypeGatewayForm, i)"
                [class.selected]="selectedGroupGatewayTypeIndex === i">
                <div class="tab-delete" (click)="removeGroupedTypeGateway(i)"><mat-icon>delete</mat-icon></div>
                <div class="tab-icon"><mat-icon>settings</mat-icon></div>
                <div class="tab-title">{{ 'Gateway ' }}</div>
                <div class="tab-subtitle">{{ '[' + groupedTypeGatewayForm.get('gateway').value + ']' }}</div>
              </div>

              <div class="group-gateways-tab-item add" (click)="addGroupedTypeGateway()">
                <div class="tab-icon"><mat-icon svgIcon="widgetNew"></mat-icon></div>
                <div class="tab-title">{{ 'wc.admin.notification.type.gateway.add' | translate }}</div>
              </div>

              <div style="clear: both"></div>
            </div>

            <div class="group-detail" *ngIf="selectedGroupGatewayTypeForm">
              <div class="card__header">
                {{ 'Gateway [' + selectedGroupGatewayTypeForm.get('gateway').value + ']' }}
                <div
                  class="locales"
                  *ngFor="let typeGateway of selectedGroupGatewayTypeForm.get('typeGateways').controls; let j = index"
                  (click)="selectGatewayType(typeGateway, j)"
                  [class.selected]="selectedGatewayTypeIndex === j">
                  {{ typeGateway.get('locale').value }}
                </div>
                <a
                  color="primary"
                  mat-raised-button
                  (click)="deleteGatewayTypeLanguage(selectedGroupGatewayTypeIndex, selectedGatewayTypeIndex)"
                  [disabled]="selectedGroupGatewayTypeForm.get('typeGateways').controls.length === 0"
                  [routerLink]=""
                  class="btn -primary -xs"
                  >{{ 'wc.admin.notification.type.gateway.delete' | translate }}</a
                >
                <a
                  color="primary"
                  mat-raised-button
                  (click)="addGatewayTypeLanguage(selectedGroupGatewayTypeIndex)"
                  [disabled]="selectedGroupGatewayTypeForm.get('typeGateways').controls.length === locales.length"
                  [routerLink]=""
                  class="btn -primary -xs"
                  >{{ 'wc.admin.notification.type.locale.add' | translate }}</a
                >
              </div>
              <div class="card__body">
                <app-notification-type-template
                  *ngIf="selectedGatewayTypeForm"
                  [typeGatewayFormGroup]="selectedGatewayTypeForm"
                  [index]="selectedGatewayTypeIndex"
                  [indexGroup]="selectedGroupGatewayTypeIndex"
                  [textTypes]="textTypes">
                </app-notification-type-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
