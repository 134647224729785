import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { CurrentLocaleService } from '@btl/btl-fe-wc-common';

@Directive()
export abstract class AbstractDashboardWidgetComponent implements OnDestroy {
  protected onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.destroy();
  }

  protected destroy(): void {

  }

  protected startDate: Date;
  protected endDate: Date;
  protected previousStartDate: Date;
  protected previousEndDate: Date;

  protected dates = [
    { labelKey: 'wc.common.date.last-24h', value: 'last-24h' },
    { labelKey: 'wc.common.date.last-7-days', value: 'last-7-days' },
    { labelKey: 'wc.common.date.last-30-days', value: 'last-30-days' },
  ];

  protected filterForm = this.formBuilder.group({
    date: [],
  });

  constructor(
    protected formBuilder: FormBuilder,
    protected currentLocaleService: CurrentLocaleService
  ) {}

  periodChange(value) {
    switch (value) {
      case 'last-24h':
        this.endDate = new Date(); // Use current date and time
        this.startDate = new Date(this.endDate.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours from current time in milliseconds
        this.previousStartDate = new Date(this.startDate);
        this.previousStartDate.setDate(this.previousStartDate.getDate() - 1);
        break;
      case 'last-7-days':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7); // Subtract 7 days from current date
        this.endDate = new Date(); // End date remains current date and time
        this.previousStartDate = new Date(this.startDate);
        this.previousStartDate.setDate(this.previousStartDate.getDate() - 7);
        break;
      case 'last-30-days':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30); // Subtract 30 days from current date
        this.endDate = new Date(); // End date remains current date and time
        this.previousStartDate = new Date(this.startDate);
        this.previousStartDate.setDate(this.previousStartDate.getDate() - 30);
        break;
    }

    this.previousEndDate = new Date(this.startDate);
  }

  getProductName(product): string {
    const productName = product?.texts.find(text => text.textType === 'PRODUCT_NAME' && text.locale === this.currentLocaleService.getCurrentLanguage())?.message;
    return productName ? productName : product.description;
  }

  getColor(index) {
    index++;
    if (index % 3 == 0) {
      return 'rgba(38, 198, 218, 1)';
    }
    if (index % 2 == 0) {
      return 'rgba(182, 99, 238, 1)';
    }
    if (index % 1 == 0) {
      return 'rgba(252, 143, 59, 1)';
    }
  }
}
